import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import 'bootstrap/dist/css/bootstrap.min.css';
import './bookmaker.css';

const Bookmaker = () => {
  const [isTableVisible, setTableVisible] = useState(true);
  const [selectedBet, setSelectedBet] = useState(null);
  const [points, setPoints] = useState(0);
  const [amount, setAmount] = useState(0);
  const [showBettingSection, setShowBettingSection] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [newAmountInput, setNewAmountInput] = useState(true);
  const [betBackgroundColor, setBetBackgroundColor] = useState('');

  const handleToggle = () => {
    setTableVisible(!isTableVisible);
  };

  const handleBetClick = (team, pointsValue, color) => {
    setSelectedBet({ team, pointsValue });
    setPoints(pointsValue);
    setAmount(100);
    setBetBackgroundColor(color);
    setShowBettingSection(true);

    if (timeoutId) clearTimeout(timeoutId);

    const id = setTimeout(() => {
      setShowBettingSection(false);
    }, 7000);
    setTimeoutId(id);
  };

  const handlePointsChange = (value) => {
    setPoints((prev) => Math.max(prev + value, 0));
  };

  const handleAmountChange = (value) => {
    setAmount((prev) => Math.max(prev + value, 0));
  };

  const handleQuickBet = (value) => {
    setAmount(value);
    setNewAmountInput(true);
  };

  const handleNumberInput = (number) => {
    setAmount(newAmountInput ? number : amount * 10 + number);
    setNewAmountInput(false);
  };

  const handleCancel = () => {
    setShowBettingSection(false);
    setSelectedBet(null);
    setPoints(0);
    setAmount(0);
  };

  const handlePlaceBet = () => {
    alert(`Bet placed on ${selectedBet.team} with ${points} points and an amount of ${amount}`);
    handleCancel();
  };

  return (
    <div className="container-fluid m-0 p-0">
      <button onClick={handleToggle} className="mat d-flex justify-content-between fw-bold w-100 m-0 p-0">
        <span>Bookmaker 0 commission</span>
        {isTableVisible ? <RemoveIcon /> : <AddIcon />}
      </button>

      {isTableVisible && (
        <div>
          <table className="table bg-light">
            <thead>
              <tr>
                <td className="MIN1">MIN: 100 | MAX: 50000</td>
                <th className="text-center col-2 border-dark back1" style={{ backgroundColor: '#74bdef ', color: 'black' }}>
                  Back
                </th>
                <th className="text-center col-2 back2" style={{ backgroundColor: 'pink', color: 'black' }}>
                  Lay
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="fw-bold">
                <td>India</td>
                <td
                  className="text-center blue2 col-3 fw-bold"
                  style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                  onClick={() => handleBetClick('India', 1.5, '#3498db')}
                >
                  <span className="fw-bold">1.5</span>
                  <br />
                  {/* <span style={{ fontSize: '12px' }}>2.34K</span> */}
                </td>
                <td
                  className="text-center blue2 col-3 fw-bold"
                  style={{ backgroundColor: 'pink', color: 'black', cursor: 'pointer' }}
                  onClick={() => handleBetClick('India', 2.38, 'pink')}
                >
                  <span className="fw-bold">2.38</span>
                  <br />
                  {/* <span style={{ fontSize: '12px' }}>2.34K</span> */}
                </td>
              </tr>
              <tr className="fw-bold">
                <td>Australia</td>
                <td
                  className="text-center blue2 fw-bold"
                  style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                  onClick={() => handleBetClick('Australia', 180, '#3498db')}
                >
                  <span className="fw-bold">180</span>
                  <br />
                  <span style={{ fontSize: '12px' }}>2.34K</span>
                </td>
                <td
                  className="text-center blue2 fw-bold"
                  style={{ backgroundColor: 'pink', color: 'black', cursor: 'pointer' }}
                  onClick={() => handleBetClick('Australia', 2.34, 'pink')}
                >
                  <span className="fw-bold">2.34</span>
                  <br />
                  <span style={{ fontSize: '12px' }}>2.34K</span>
                </td>
              </tr>
             
            </tbody>
            <tr class="m-0 p-0"><td colspan="3">
            <span class="fancymassage float-right bmmassage"  
              style={{color:'red'}}><i class="fa fa-envelope mr-1"></i> 
              Aviator 🛩 Games Started in Our Exchange..</span>
</td></tr>
          </table>

          {showBettingSection && (
            <div className="m-0 p-0">
              {/* diffrent background color dene k liye  */}
              <div className="row m-0 p-0"  style={{ backgroundColor: betBackgroundColor }}> 
                <div
                  className="col-6 col-lg-6 col-sm-6 m-0 p-0 d-flex justify-content-between align-items-center increament1 fristsection"
                  // style={{ backgroundColor: betBackgroundColor }}
                >
                  <button onClick={() => handlePointsChange(-0.1)} className="btn12">
                    -
                  </button>
                  <span>{points}</span>
                  <button onClick={() => handlePointsChange(0.1)} className="btn12">
                    +
                  </button>
                </div>
                <div className="col-6 col-lg-6 col-sm-6 m-0 p-0 d-flex justify-content-between align-items-center increament1 fristsection"
                // style={{ backgroundColor: betBackgroundColor }}
                >
                  <button onClick={() => handleAmountChange(-100)} className="btn12">
                    -
                  </button>
                  <span>{amount}</span>
                  <button onClick={() => handleAmountChange(100)} className="btn12">
                    +
                  </button>
                </div>
              </div>

{/* diffrent background color use kar rha hu */}
              <div className="row p-0 m-0 betting-section" style={{ backgroundColor: betBackgroundColor }}>

                <div className="quick-bet-buttons p-0 m-0  "  >
                  {[100, 500, 1000, 5000, 50000].map((value) => (
                    <button key={value} onClick={() => handleQuickBet(value)} className="btn11"
                     style={{ backgroundColor: betBackgroundColor }}>
                      {value}
                    </button>
                  ))}
                </div>
              </div>

              <div className="row m-0 p-0">
                <div className="col-10 m-0 p-0">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, '0'].map((number) => (
                    <button key={number} onClick={() => handleNumberInput(number)} className="btn14">
                      {number}
                    </button>
                  ))}
                </div>
                <div className="col-2 border border-info m-0 p-0">
                  <button onClick={() => setAmount(0)} className="fw-bold m-0 p-0 text-dark btnm">
                    Clear
                  </button>
                </div>
              </div>

              <div className="row m-0 p-0">
                <div className="col-6 m-0 p-0">
                  <button onClick={handleCancel} className="btn btn-danger w-100 btnn  fw-bold">
                    Cancel
                  </button>
                </div>
                <div className="col-6 m-0 p-0">
                  <button onClick={handlePlaceBet} className="btn btn-success w-100 btnnnn  fw-bold">
                    Place Bet
                  </button>
                </div>
              </div>
              <div className='row'>
              <div className='col-6 col-sm-6 col-md-6'>
                
                 </div>
                 <div className='col-6 col-sm-6 col-md-6 border border-info'>
                
                 </div>
                 </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
  
};

export default Bookmaker;
