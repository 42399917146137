import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../../main.css"
import "./Sidebar3.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaUser} from "react-icons/fa6";
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneIcon from "@mui/icons-material/Phone"; // Contact icon
import InfoIcon from "@mui/icons-material/Info"; // Info icon
import HomeIcon from "@mui/icons-material/Home"; // Home icon

const Sidebar3 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    navigate("/");
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      PaperProps={{ className: "sidebar3" }}
    >
        {/* doubt is here  var(--background-color) */}
<Box sx={{ width: "100%", backgroundColor: "#17a2b8", height: "100%" }} > 
<Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
          backgroundColor: "#17a2b8",
          color: "#FFF",
        }}>
          <Typography variant="p">EXCHANGE</Typography>
         
          <IconButton onClick={handleClose} sx={{ color: "#FFF" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{  }} />

        {/* Contact Icon and Text */}
        <Box sx={{ display: "flex", alignItems: "center", padding: "16px" }}>
          {/* <PhoneIcon sx={{ color: "#FFF" }} /> */}
          <FaUser style={{ color: "yellow", fontSize: "24px" }} /> {/* User Icon */}
          
          <Typography variant="body1" sx={{ marginLeft: 1, color: "#FFF" }}>
            Demo - (2000)
          </Typography>
        </Box>

        {/* Row with two col-6 boxes */}
        <Box sx={{ display: "flex", padding: 2 }}>
          <Box sx={{
            flex: "1 1 50%",
            backgroundColor: "#5ac8fa", //dono boxes k liye bg 
            marginRight: "4px",
            padding: "10px",
            height:"60px"
          }}>
            <Link to="" style={{ color: "#FFF", textDecoration: "none" }}>
              <Typography variant="body1">Exposure</Typography>
              <Typography variant="body1" class="text-dark">0.00</Typography>
            </Link>
          </Box>
          <Box sx={{
            flex: "1 1 50%",
            backgroundColor: "#5ac8fa",
            padding: "10px",
            height:"60px",
          }}>
            <Link to="/link2" style={{ color: "#FFF", textDecoration: "none" }}>
              <Typography variant="body1">P&L</Typography>
              <Typography className="text-dark fw-bold" variant="body1 ">0.00</Typography>
            </Link>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", padding: "16px" }}>
          <InfoIcon sx={{ color: "#FFF" }} />
          <Link to="" style={{ marginLeft: 8, color: "#FFF", textDecoration: "none" }}>
            <Typography variant="body1">Rules</Typography>
          </Link>
        </Box>
        {/* Another Row with Icon and Text */}
        <Box sx={{ display: "flex", alignItems: "center", padding: "16px" }}>
          <InfoIcon sx={{ color: "#FFF" }} />
          <Link to="" style={{ marginLeft: 8, color: "#FFF", textDecoration: "none" }}>
          <button type="button"  style={{color:"light"}}>
      <FontAwesomeIcon icon={faSignOutAlt} /> Logout
    </button>
          </Link>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar3;










