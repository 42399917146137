import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import 'bootstrap/dist/css/bootstrap.min.css';
import './matchesdata.css';

const Matchesdata = () => {
  const [isTableVisible, setTableVisible] = useState(false);
  const [selectedBet, setSelectedBet] = useState(null);
  const [points, setPoints] = useState(0);
  const [amount, setAmount] = useState(0);
  const [showBettingSection, setShowBettingSection] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [newAmountInput, setNewAmountInput] = useState(true);

  const handleToggle = () => {
    setTableVisible(!isTableVisible);
  };

  const handleBetClick = (team, pointsValue) => {
    setSelectedBet({ team, pointsValue });
    setPoints(pointsValue);
    setAmount(100);
    setShowBettingSection(true);

    if (timeoutId) clearTimeout(timeoutId);

    const id = setTimeout(() => {
      setShowBettingSection(false);
    }, 70000);
    setTimeoutId(id);
  };

  const handlePointsChange = (value) => {
    setPoints((prev) => Math.max(prev + value, 0));
  };

  const handleAmountChange = (value) => {
    setAmount((prev) => Math.max(prev + value, 0));
  };

  const handleQuickBet = (value) => {
    setAmount(value);
    setNewAmountInput(true);
  };

  const handleNumberInput = (number) => {
    setAmount(newAmountInput ? number : amount * 10 + number);
    setNewAmountInput(false);
  };

  const handleCancel = () => {
    setShowBettingSection(false);
    setSelectedBet(null);
    setPoints(0);
    setAmount(0);
  };

  const handlePlaceBet = () => {
    alert(`Bet placed on ${selectedBet.team} with ${points} points and an amount of ${amount}`);
    handleCancel();
  };

  return (
    <> 
    <div className="container mt-3">
      <button onClick={handleToggle} className="btn btn-primary mb-3 d-flex align-items-center">
        {isTableVisible ? 'Hide Data' : 'Show Data'}
        {isTableVisible ? <RemoveIcon className="ms-2" /> : <AddIcon className="ms-2" />}
      </button>

      {isTableVisible && (
        <div>
          <table className="table table-bordered mt-3">
            <thead>
              <tr>
                <th>Team Name</th>
                <th className="text-center col-2">Data 1</th>
                <th className="text-center col-2">Data 2</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>India</td>
                <td
                  className="text-center"
                  style={{ backgroundColor: 'blue', color: 'white', cursor: 'pointer' }}
                  onClick={() => handleBetClick('India', 150)}
                >
                  150
                </td>
                <td className="text-center" style={{ backgroundColor: 'pink', color: 'black' }}>
                  75
                </td>
              </tr>
              <tr>
                <td>Australia</td>
                <td
                  className="text-center"
                  style={{ backgroundColor: 'blue', color: 'white', cursor: 'pointer' }}
                  onClick={() => handleBetClick('Australia', 180)}
                >
                  180
                </td>
                <td className="text-center" style={{ backgroundColor: 'pink', color: 'black' }}>
                  90
                </td>
              </tr>
            </tbody>
          </table>

          {showBettingSection && (
            <div className="betting-section mt-3 p-3 border bg-light">
              <h5>Place Bet on {selectedBet.team}</h5>

              <div className="row mb-3">
                <div className="col-6 d-flex justify-content-start align-items-center border border-dark">
                  <button onClick={() => handlePointsChange(-0.1)}>-</button>
                  <span> {points}</span>
                  <button onClick={() => handlePointsChange(0.1)}>+</button>
                </div>
                <div className="col-6 d-flex justify-content-between align-items-center">
                  <button onClick={() => handleAmountChange(-100)}>-</button>
                  <span> {amount}</span>
                  <button onClick={() => handleAmountChange(100)}>+</button>
                </div>
              </div>

              <div className="row mb-3">
                {[100, 500, 1000, 5000, 50000].map((value) => (
                  <div className="col" key={value}>
                    <button onClick={() => handleQuickBet(value)} className="btn btn-secondary w-100">
                      {value}
                    </button>
                  </div>
                ))}
              </div>

              <div className="row mb-3">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, '00'].map((number) => (
                  <div className="col-4 mb-1" key={number}>
                    <button
                      onClick={() => handleNumberInput(number)}
                      className="btn btn-outline-dark w-100"
                    >
                      {number}
                    </button>
                  </div>
                ))}
              </div>

              <div className="row mt-3">
                <div className="col-6">
                  <button onClick={handleCancel} className="btn btn-danger w-100">
                    Cancel
                  </button>
                </div>
                <div className="col-6">
                  <button onClick={handlePlaceBet} className="btn btn-success w-100">
                    Place Bet
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
       
      )}
    </div>

    <div className='container ' style={{height:'200px'}}></div>

</>
  );
};

export default Matchesdata;
