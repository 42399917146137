import React, { useState, useEffect } from 'react';
import './slider.css';
import img1 from '../../assets/popup1.jpg';
import img2 from '../../assets/popup2.jpg';
import img3 from '../../assets/popup3.jpg';
import img4 from '../../assets/popup4.jpg';
import img5 from '../../assets/popup5.jpg';
import img6 from '../../assets/popup6.jpg';
import img9 from '../../assets/popup9.jpg';
import img8 from '../../assets/popup8.jpg';
import img10 from '../../assets/popup10.jpg';
import img11 from '../../assets/popup11.jpg';

const Slider = () => {
  const images = [
    img1, img2, img3, img4, img5, img6, img9, img8, img10, img11,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  // Set up autoplay with useEffect
  useEffect(() => {
    const interval = setInterval(goToNext, 3000); // Change image every 3 seconds
    return () => clearInterval(interval); // Clean up on component unmount
  }, [currentIndex]);

  return (
    <div className="background-slider-container">
      <div className="background-slide" style={{ backgroundImage: `url(${images[currentIndex]})` }}></div>
    
    </div>
  );
};

export default Slider;
