// import React, { useState } from 'react';
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './fancy.css';

// const Fancy = ({ activeBetComponent, onPlaceBetClick }) => {
//   const [isTableVisible, setTableVisible] = useState(true);
//   const [selectedBet, setSelectedBet] = useState(null);
//   const [points, setPoints] = useState(0);
//   const [amount, setAmount] = useState(0);
//   const [showBettingSection, setShowBettingSection] = useState(false);
//   const [timeoutId, setTimeoutId] = useState(null);
//   const [newAmountInput, setNewAmountInput] = useState(true);
//   const [betBackgroundColor, setBetBackgroundColor] = useState('');
//   const [activeTab, setActiveTab] = useState('all'); // for switching between tabs

//   const handleToggle = () => {
//     setTableVisible(!isTableVisible);
//   };

//   const handleBetClick = (team, pointsValue, color) => {
//     setSelectedBet({ team, pointsValue });
//     setPoints(pointsValue);
//     setAmount(100);
//     setBetBackgroundColor(color);
//     setShowBettingSection(true);

//     if (timeoutId) clearTimeout(timeoutId);

//     const id = setTimeout(() => {
//       setShowBettingSection(false);
//     }, 7000);
//     setTimeoutId(id);
//   };

//   const handlePointsChange = (value) => {
//     setPoints((prev) => Math.max(prev + value, 0));
//   };

//   const handleAmountChange = (value) => {
//     setAmount((prev) => Math.max(prev + value, 0));
//   };

//   const handleQuickBet = (value) => {
//     setAmount(value);
//     setNewAmountInput(true);
//   };

//   const handleNumberInput = (number) => {
//     setAmount(newAmountInput ? number : amount * 10 + number);
//     setNewAmountInput(false);
//   };

//   const handleCancel = () => {
//     setShowBettingSection(false);
//     setSelectedBet(null);
//     setPoints(0);
//     setAmount(0);
//   };

//   const handlePlaceBet = () => {
//     alert(`Bet placed on ${selectedBet.team} with ${points} points and an amount of ${amount}`);
//     handleCancel();
//   };

//   return (
//     <>
//     <div className="container-fluid">
//         <div className='row m-0 p-0'>
//             <div className='col-3 m-0 p-0'>

           

        
//       <button onClick={handleToggle} className="mat1  fw-bold w-100 m-0 p-0">
//         <span className=''>fancy</span>
//         <span className='ms-4'> <RemoveIcon /></span>
       
//       </button>

//       </div>
//       <div className='col-9'></div>
//       </div>

//       {isTableVisible && (
//         <div>
//             <div className='row m-0 p-0 mat2' >
//             <div className='col-6 col-sm-6 m-0 p-0'>

//           <div className="d-flex justify-content-between m-0 p-0 " 
//           style={{backgroundColor:'#74bdef'}}>
//             <button style={{backgroundColor:'',color:'white',fontSize:'15px'}} 
//             onClick={() => setActiveTab('all')} className={`tab-button mat2 ${activeTab === 'all' ? 'active' : ''}`}>All</button>
//             <button style={{backgroundColor:'#74bdef',color:'white',fontSize:'15px'}}
//              onClick={() => setActiveTab('over')} className={`tab-button mat2 ${activeTab === 'over' ? 'active' : ''}`}>Over</button>
//             <button style={{backgroundColor:'#74bdef',color:'white',fontSize:'15px'}}
//             onClick={() => setActiveTab('batsman')} className={`tab-button mat2 ${activeTab === 'batsman' ? 'active' : ''}`}>Batsman</button>
//           </div>
//           </div>
//             </div>

//           <div className="mt-1">
//             {activeTab === 'all' && (
//               <table className="table bg-light">
//                 <thead>
//                   <tr>
//                     <td className="MIN1">MIN: 100 | MAX: 50000</td>
//                     <th className="text-center col-2 border-dark back1" style={{ backgroundColor: '#74bdef ', color: 'black' }}>
//                       Back
//                     </th>
//                     <th className="text-center col-2 back2" style={{ backgroundColor: 'pink', color: 'black' }}>
//                       Lay
//                     </th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   <tr className="fw-bold">
//                     <td>India</td>
//                     <td className="text-center blue2 col-3 fw-bold" style={{ backgroundColor: '#74bdef', cursor: 'pointer' }} onClick={() => handleBetClick('India', 1.5, '#74bdef')}>
//                       <span className="fw-bold">1.5</span>
//                       <br />
//                       <span style={{ fontSize: '12px' }}>2.34K</span>
//                     </td>
//                     <td className="text-center blue2 col-3 fw-bold" style={{ backgroundColor: 'pink', cursor: 'pointer' }} onClick={() => handleBetClick('India', 2.38, 'pink')}>
//                       <span className="fw-bold">2.38</span>
//                       <br />
//                       <span style={{ fontSize: '12px' }}>2.34K</span>
//                     </td>
//                   </tr>
//                   <tr className="fw-bold">
//                     <td>Australia</td>
//                     <td className="text-center blue2 fw-bold" style={{ backgroundColor: '#74bdef', cursor: 'pointer' }} onClick={() => handleBetClick('Australia', 180, '#74bdef')}>
//                       <span className="fw-bold">180</span>
//                       <br />
//                       <span style={{ fontSize: '12px' }}>2.34K</span>
//                     </td>
//                     <td className="text-center blue2 fw-bold" style={{ backgroundColor: 'pink', cursor: 'pointer' }} onClick={() => handleBetClick('Australia', 2.34, 'pink')}>
//                       <span className="fw-bold">2.34</span>
//                       <br />
//                       <span style={{ fontSize: '12px' }}>2.34K</span>
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             )}

//             {activeTab === 'over' && (
//               <div className="tab-content">
//                 {/* Add Over-specific content here */}
//                 <p>Over content goes here</p>
//               </div>
//             )}

//             {activeTab === 'batsman' && (
//               <div className="tab-content">
//                 {/* Add Batsman-specific content here */}
//                 <p>Batsman content goes here</p>
//               </div>
//             )}
//           </div>

//           {showBettingSection && (
//             <div className="m-0 p-0">
//               <div className="row m-0 p-0" style={{ backgroundColor: betBackgroundColor }}>
//                 <div className="col-6 col-lg-6 col-sm-6 m-0 p-0 d-flex justify-content-between align-items-center increament1 fristsection">
//                   <button onClick={() => handlePointsChange(-0.1)} className="btn12">-</button>
//                   <span>{points}</span>
//                   <button onClick={() => handlePointsChange(0.1)} className="btn12">+</button>
//                 </div>
//                 <div className="col-6 col-lg-6 col-sm-6 m-0 p-0 d-flex justify-content-between align-items-center increament1 fristsection">
//                   <button onClick={() => handleAmountChange(-100)} className="btn12">-</button>
//                   <span>{amount}</span>
//                   <button onClick={() => handleAmountChange(100)} className="btn12">+</button>
//                 </div>
//               </div>

//               <div className="row p-0 m-0 betting-section" style={{ backgroundColor: betBackgroundColor }}>
//                 <div className="quick-bet-buttons p-0 m-0">
//                   {[100, 500, 1000, 5000, 50000].map((value) => (
//                     <button key={value} onClick={() => handleQuickBet(value)} className="btn11" style={{ backgroundColor: betBackgroundColor }}>
//                       {value}
//                     </button>
//                   ))}
//                 </div>
//               </div>

//               <div className="row m-0 p-0">
//                 <div className="col-10 m-0 p-0">
//                   {[1, 2, 3, 4, 5, 6, 7, 8, 9, '0'].map((number) => (
//                     <button key={number} onClick={() => handleNumberInput(number)} className="btn14">{number}</button>
//                   ))}
//                 </div>
//                 <div className="col-2 border border-info m-0 p-0">
//                   <button onClick={() => setAmount(0)} className="fw-bold m-0 p-0 text-dark btnm">Clear</button>
//                 </div>
//               </div>

//               <div className="row m-0 p-0">
//                 <div className="col-6 m-0 p-0">
//                   <button onClick={handleCancel} className="btn btn-danger w-100 btnn mt-1 fw-bold">
//                     Cancel
//                   </button>
//                 </div>
//                 <div className="col-6 m-0 p-0">
//                   <button onClick={handlePlaceBet} className="btn btn-success w-100 btnnnn mt-1 fw-bold">
//                     Place Bet
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//     <div style={{height:'300px'}}></div>
//     </>
//   );
// };

// export default Fancy;

import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import 'bootstrap/dist/css/bootstrap.min.css';
import './fancy.css';

const Fancy = ({ activeBetComponent, onPlaceBetClick }) => {
  const [isTableVisible, setTableVisible] = useState(true);
  const [selectedBet, setSelectedBet] = useState(null);
  const [points, setPoints] = useState(0);
  const [amount, setAmount] = useState(0);
  const [showBettingSection, setShowBettingSection] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const [newAmountInput, setNewAmountInput] = useState(true);
  const [betBackgroundColor, setBetBackgroundColor] = useState('');
  const [activeTab, setActiveTab] = useState('all');

  const handleToggle = () => {
    setTableVisible(!isTableVisible);
  };

  const handleBetClick = (team, pointsValue, color) => {
    setSelectedBet({ team, pointsValue });
    setPoints(pointsValue);
    setAmount(100);
    setBetBackgroundColor(color);
    setShowBettingSection(true);

    if (timeoutId) clearTimeout(timeoutId);

    const id = setTimeout(() => {
      setShowBettingSection(false);
    }, 7000);
    setTimeoutId(id);
  };

  const handlePointsChange = (value) => {
    setPoints((prev) => Math.max(prev + value, 0));
  };

  const handleAmountChange = (value) => {
    setAmount((prev) => Math.max(prev + value, 0));
  };

  const handleQuickBet = (value) => {
    setAmount(value);
    setNewAmountInput(true);
  };

  const handleNumberInput = (number) => {
    setAmount(newAmountInput ? number : amount * 10 + number);
    setNewAmountInput(false);
  };

  const handleCancel = () => {
    setShowBettingSection(false);
    setSelectedBet(null);
    setPoints(0);
    setAmount(0);
  };

  const handlePlaceBet = () => {
    alert(`Bet placed on ${selectedBet.team} with ${points} points and an amount of ${amount}`);
    handleCancel();
  };

  return (
     <>

    <div className="container-fluid m-0 p-0  border border-danger">
      <div className="row m-0 p-0 ">
        <div className="col-3 m-0 p-0">
          <button onClick={handleToggle} className="mat1 fw-bold w-100 m-0 p-0">
            <span>fancy</span>
            <span className="ms-4 ">
              <RemoveIcon />
            </span>
          </button>
        </div>
        <div className="col-9"></div>
      </div>

      {isTableVisible && (
        <div>
          <div className="row m-0 p-0 mat2">
            <div className="col-6 col-sm-6 m-0 p-0">
              <div className="d-flex justify-content-between m-0 p-0" style={{ backgroundColor: '#74bdef' }}>
                <button
                  style={{ color: 'white', fontSize: '15px' }}
                  onClick={() => setActiveTab('all')}
                  className={`tab-button mat2 ${activeTab === 'all' ? 'active' : ''}`}
                >
                  All
                </button>
                <button
                  style={{ backgroundColor: '#74bdef', color: 'white', fontSize: '15px' }}
                  onClick={() => setActiveTab('over')}
                  className={`tab-button mat2 ${activeTab === 'over' ? 'active' : ''}`}
                >
                  Over
                </button>
                <button
                  style={{ backgroundColor: '#74bdef', color: 'white', fontSize: '15px' }}
                  onClick={() => setActiveTab('batsman')}
                  className={`tab-button mat2 ${activeTab === 'batsman' ? 'active' : ''}`}
                >
                  Batsman
                </button>
              </div>
            </div>
          </div>

          <div className="  ">
            {activeTab === 'all' && (
              <table className="table bg-light m-0 p-0">
                <thead className=''>
                  <tr>
                    <td className="MIN1">MIN: 100 | MAX: 50000</td>
                    <th className="text-center col-2 border-dark back1" style={{ backgroundColor: '#74bdef', color: 'black' }}>
                      Back
                    </th>
                    <th className="text-center col-2 back2" style={{ backgroundColor: 'pink', color: 'black' }}>
                      Lay
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="fw-bold">
                    <td>India</td>
                    <td
                      className="text-center blue2 col-3 fw-bold"
                      style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                      onClick={() => handleBetClick('India', 1.5, '#74bdef')}
                    >
                      <span className="fw-bold">1.5</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>2.34K</span>
                    </td>
                    <td
                      className="text-center blue2 col-3 fw-bold"
                      style={{ backgroundColor: 'pink', cursor: 'pointer' }}
                      onClick={() => handleBetClick('India', 2.38, 'pink')}
                    >
                      <span className="fw-bold">2.38</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>2.34K</span>
                    </td>
                  </tr>
                  <tr className="fw-bold">
                    <td>Australia</td>
                    <td
                      className="text-center blue2 fw-bold"
                      style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Australia', 180, '#74bdef')}
                    >
                      <span className="fw-bold">180</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>2.34K</span>
                    </td>
                    <td
                      className="text-center blue2 fw-bold"
                      style={{ backgroundColor: 'pink', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Australia', 2.34, 'pink')}
                    >
                      <span className="fw-bold">2.34</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>2.34K</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {activeTab === 'over' && (
              <table className="table bg-light m-0 p-0">
                <thead>
                  <tr>
                    <td className="MIN1">MIN: 50 | MAX: 10000</td>
                    <th className="text-center col-2 border-dark back1" style={{ backgroundColor: '#74bdef', color: 'black' }}>
                      Back
                    </th>
                    <th className="text-center col-2 back2" style={{ backgroundColor: 'pink', color: 'black' }}>
                      Lay
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="fw-bold">
                    <td>Over 10</td>
                    <td
                      className="text-center blue2 col-3 fw-bold"
                      style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Over 10', 1.85, '#74bdef')}
                    >
                      <span className="fw-bold">1.85</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>1.5K</span>
                    </td>
                    <td
                      className="text-center blue2 col-3 fw-bold"
                      style={{ backgroundColor: 'pink', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Over 10', 2.1, 'pink')}
                    >
                      <span className="fw-bold">2.1</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>1.5K</span>
                    </td>
                  </tr>
                  <tr className="fw-bold">
                    <td>Over 20</td>
                    <td
                      className="text-center blue2 fw-bold"
                      style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Over 20', 3.0, '#74bdef')}
                    >
                      <span className="fw-bold">3.0</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>1.8K</span>
                    </td>
                    <td
                      className="text-center blue2 fw-bold"
                      style={{ backgroundColor: 'pink', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Over 20', 3.5, 'pink')}
                    >
                      <span className="fw-bold">3.5</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>1.8K</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            {activeTab === 'batsman' && (
              <table className="table bg-light">
                <thead>
                  <tr>
                    <td className="MIN1">MIN: 30 | MAX: 15000</td>
                    <th className="text-center col-2 border-dark back1" style={{ backgroundColor: '#74bdef', color: 'black' }}>
                      Back
                    </th>
                    <th className="text-center col-2 back2" style={{ backgroundColor: 'pink', color: 'black' }}>
                      Lay
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="fw-bold">
                    <td>Virat Kohli</td>
                    <td
                      className="text-center blue2 col-3 fw-bold"
                      style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Virat Kohli', 2.0, '#74bdef')}
                    >
                      <span className="fw-bold">2.0</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>3K</span>
                    </td>
                    <td
                      className="text-center blue2 col-3 fw-bold"
                      style={{ backgroundColor: 'pink', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Virat Kohli', 2.5, 'pink')}
                    >
                      <span className="fw-bold">2.5</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>3K</span>
                    </td>
                  </tr>
                  <tr className="fw-bold">
                    <td>Rohit Sharma</td>
                    <td
                      className="text-center blue2 fw-bold"
                      style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Rohit Sharma', 1.8, '#74bdef')}
                    >
                      <span className="fw-bold">1.8</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>2.5K</span>
                    </td>
                    <td
                      className="text-center blue2 fw-bold"
                      style={{ backgroundColor: 'pink', cursor: 'pointer' }}
                      onClick={() => handleBetClick('Rohit Sharma', 2.2, 'pink')}
                    >
                      <span className="fw-bold">2.2</span>
                      <br />
                      <span style={{ fontSize: '12px' }}>2.5K</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>

          {/* Betting Section */}
          {showBettingSection && selectedBet && (


<div className="m-0 p-0">
{/* diffrent background color dene k liye  */}
<div className="row m-0 p-0"  style={{ backgroundColor: betBackgroundColor }}> 
  <div
    className="col-6 col-lg-6 col-sm-6 m-0 p-0 d-flex justify-content-between align-items-center increament1 fristsection"
    // style={{ backgroundColor: betBackgroundColor }}
  >
    <button onClick={() => handlePointsChange(-0.1)} className="btn12">
      -
    </button>
    <span>{points}</span>
    <button onClick={() => handlePointsChange(0.1)} className="btn12">
      +
    </button>
  </div>
  <div className="col-6 col-lg-6 col-sm-6 m-0 p-0 d-flex justify-content-between align-items-center increament1 fristsection"
  // style={{ backgroundColor: betBackgroundColor }}
  >
    <button onClick={() => handleAmountChange(-100)} className="btn12">
      -
    </button>
    <span>{amount}</span>
    <button onClick={() => handleAmountChange(100)} className="btn12">
      +
    </button>
  </div>
</div>

{/* diffrent background color use kar rha hu */}
<div className="row p-0 m-0 betting-section" style={{ backgroundColor: betBackgroundColor }}>

  <div className="quick-bet-buttons p-0 m-0  "  >
    {[100, 500, 1000, 5000, 50000].map((value) => (
      <button key={value} onClick={() => handleQuickBet(value)} className="btn11"
       style={{ backgroundColor: betBackgroundColor }}>
        {value}
      </button>
    ))}
  </div>
</div>

<div className="row m-0 p-0">
  <div className="col-10 m-0 p-0">
    {[1, 2, 3, 4, 5, 6, 7, 8, 9, '0'].map((number) => (
      <button key={number} onClick={() => handleNumberInput(number)} className="btn14">
        {number}
      </button>
    ))}
  </div>
  <div className="col-2 border border-info m-0 p-0">
    <button onClick={() => setAmount(0)} className="fw-bold m-0 p-0 text-dark btnm">
      Clear
    </button>
  </div>
</div>

<div className="row m-0 p-0">
  <div className="col-6 m-0 p-0">
    <button onClick={handleCancel} className="btn btn-danger w-100 btnn mt-1 fw-bold">
      Cancel
    </button>
  </div>
  <div className="col-6 m-0 p-0">
    <button onClick={handlePlaceBet} className="btn btn-success w-100 btnnnn mt-1 fw-bold">
      Place Bet
    </button>
  </div>
</div>

<div className='row'>
<div className='col-6 col-sm-6 col-md-6'>
  
   </div>
   <div className='col-6 col-sm-6 col-md-6 border border-info'>
  
   </div>
   </div>
</div>

          )}
        </div>
      )}
    </div>
 
    </>
  );
};

export default Fancy;
