import React, { useState } from 'react';
import '../../../main.css';
import './footer.css';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faSearch, faTrophy, faUser } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from "react-router-dom";
import inplay from '../../../assets/inplaywhite.img';
import homelogo from '../../../assets/home2.img';
import minigames from '../../../assets/mini_games.gif';
import menuimg from '../../../assets/menu2.jpg';

//matches k liye 
import "bootstrap/dist/css/bootstrap.min.css";



const Footer = () => {
  const [showNoticeModal, setShowNoticeModal] = useState(false);

  const handleShowNoticeModal = () => {
    setShowNoticeModal(true);
  };

  const handleCloseNoticeModal = () => {
    setShowNoticeModal(false);
    
    window.location.href = "/MyAccount"; 
  };

  return (
    <footer className="footer text-white">
      <Container className='py-1'>
        <Row className="justify-content-center">
          <Col xs={3} md={2} className="text-center">
            <NavLink className="nav-link fw-normal homelogo" to="/" >
              <img src={homelogo} height={'40px'} width={'40px'}></img>
            </NavLink>
          </Col>
          <Col xs={3} md={2} className="text-center">
            <NavLink className="nav-link  fw-normal  homelogo" to="/Inplay">
              <img src={inplay} height={'40px'} width={'40px'}></img> 
            </NavLink>
          </Col>
          <Col xs={3} md={2} className="text-center">
            <NavLink className="nav-link  fw-normal  homelogo" to="/Login">
            <img src={minigames} height={'40px'} width={'40px'}></img> 
            </NavLink>
          </Col>
          <Col xs={3} md={2} className="text-center">
            <NavLink className="nav-link   fw-normal  homelogo" to="/Sidebar3">
            <img src={menuimg} height={'40px'} width={'40px'} /> 
            </NavLink>
          </Col>
        </Row>
      </Container>

{/*       
      <Modal show={showNoticeModal} onHide={handleCloseNoticeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
           
          <li>FAST RECHARGE AND</li>
              <li>WITHRAWAL SYSTEM Fast</li>
              <li>NOTE :- NEW ACCOUNT OPEN </li>
              <li>FAST DIPOSIT REACHARGE 10-50% BONUS</li>
              <li>NEW OFFER </li>
              <li>NEW REGISTRATION AND TELEGRAM</li>
              <li>10 NEW REGISTRATION 2 ID ACTIVE PR 200 MILEGA</li>
              <li>20 NEW REGISTRATION 4 ID ACTIVE PR 400 MILEGA</li>
              <li>50 NEW REGISTRATION 10 ID ACTIVE PR 1100 MILEGA</li>
              <li>100 NEW REGISTRATION 20 ID ACTIVE PR 2500 MILEGA</li>
              <li>200 NEW REGISTRATION 40 ID ACTIVE PR 5100 MILEGA</li>
              <li>ONLY NEW REGISTER AND MINIMUM 100 RS SE JOIN KARANA H</li>
             
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNoticeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}

    </footer>
  );
};

export default Footer;
