import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './crousel.css';
import crouselImage1 from '../../../assets/crousel1.img';
import crouselImage2 from '../../../assets/crousel2.img';
import crouselImage3 from '../../../assets/crousel3.img';
import crouselImage4 from '../../../assets/crousel4.img';
import crouselImage5 from '../../../assets/crousel5.img';
import crouselImage6 from '../../../assets/crousel6.img';
import crouselImage7 from '../../../assets/crousel7.img';
import crouselImage8 from '../../../assets/crousel8.img';
import crouselImage9 from '../../../assets/crousel9.img';
import crouselImage10 from '../../../assets/crousel10.img';
import crouselImage11 from '../../../assets/crousel11.img';

const ImageCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 9
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 9
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 4
    }
  };

  const images = [
    crouselImage1,
    crouselImage2,
    crouselImage3,
    crouselImage4,
    crouselImage5,
    crouselImage6,
    crouselImage7,
    crouselImage8,
    crouselImage9,
    crouselImage10,
    crouselImage11,
    // Aur bhi images yahan add karein
  ];

  return (
    <Carousel
      responsive={responsive}
      arrows={false} // Arrows ko disable karne ke liye
      renderArrow={null} // Arrows ko hide karne ke liye
    >
      {images.map((image, index) => (
        <div key={index} style={{ padding: '10px' }}>
          <img src={image} alt={`Image ${index + 1}`} className='crousel1' style={{ width: '100%', height: 'auto' }} />
        </div>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
