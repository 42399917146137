
import { NavLink } from 'react-router-dom';
import '../../main.css';


import React, { useState } from 'react';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [captcha, setCaptcha] = useState(generateCaptcha());
  const [captchaInput, setCaptchaInput] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showLogin, setShowLogin] = useState(true);

  const validateLogin = () => {
    if (username === '' || password === '' || captchaInput === '') {
      setErrorMessage('Please fill in all fields.');
      return;
    }

    if (captchaInput !== captcha) {
      setErrorMessage('Incorrect captcha. Please try again.');
      setCaptcha(generateCaptcha()); // regenerate captcha on incorrect entry
      return;
    }

    if (username === 'admin' && password === 'password123') {
      setErrorMessage('');
      alert(`Login successful! Remember Me: ${rememberMe}`);
    } else {
      setErrorMessage('Invalid username or password.');
    }
  };

  function generateCaptcha() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return Array.from({ length: 6 })
      .map(() => characters[Math.floor(Math.random() * characters.length)])
      .join('');
  }

  const refreshCaptcha = () => {
    setCaptcha(generateCaptcha());
    setCaptchaInput('');
  };

  const handleClose = () => {
    setShowLogin(false);
  };

  if (!showLogin) {
    return null;
  }

  return (
    <>
    
    <div className='mb-5'   >
   
    <div style={styles.container}>
      <div style={{ ...styles.loginBox,borderColor:' #18b0c8', borderRadius: '10px', borderStyle: 'solid', borderWidth: '7px' }}>
        <h2>Login</h2>
        
        <button onClick={handleClose} style={styles.closeButton}>X</button>

        <label style={styles.label}>UserID</label>
        <input
          type="text"
          placeholder="UserID"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={{ ...styles.input, borderRadius:'15px' }}
        />

        <div style={styles.checkboxContainer}>
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            style={styles.checkbox}
          />
          <label>Remember Me</label>
        </div>

        <label style={styles.label}>Password</label>
        <div style={styles.passwordContainer}>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ ...styles.input, borderRadius: '15px' }}
          />
          <button onClick={() => setShowPassword(!showPassword)} style={styles.showPasswordButton}>
            {showPassword ? '🙈' : '👁️'}
          </button>
        </div>

        {/* Captcha Section */}
        <div style={styles.captchaContainer}>
          <div style={styles.captchaDisplay}>
            <span>{captcha}</span>
            <button onClick={refreshCaptcha} style={styles.refreshButton}>🔄</button>
          </div>
          <input
            type="text"
            placeholder="Enter Captcha"
            value={captchaInput}
            onChange={(e) => setCaptchaInput(e.target.value)}
            style={styles.input}
          />
        </div>

        <button onClick={validateLogin} style={styles.button}>Login</button>
        <NavLink to="/Demo" className='fw-bold sher  '>
        <button onClick={validateLogin} style={styles.button} className='mt-2'>Demo ID</button>
        </NavLink>
        

        {errorMessage && <p style={styles.error}>{errorMessage}</p>}
      </div>
    </div>
    </div>
    </>
  );
};



const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
  
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#055160', // Background color updated
  },
  loginBox: {
    padding: '10px',
    backgroundColor: '#fff',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    width: '350px',
    textAlign: 'center',
    borderRadius: '15px',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  label: {
    textAlign: 'left',
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    margin: '5px 0',
    border: '1px solid #ccc',
  },
  passwordContainer: {
    position: 'relative',
  },
  showPasswordButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  },
  checkboxContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '5px 0',
  },
  checkbox: {
    marginRight: '5px',
  },
  captchaContainer: {
    marginTop: '10px',
  },
  captchaDisplay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#e9e9e9',
    padding: '10px',
    borderRadius: '5px',
  },
  refreshButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  },
  button: {
    width: '100%',
    padding: '10px',
    backgroundColor: '#18b0c8',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    marginTop: '10px',
  },
};


export default LoginPage;
