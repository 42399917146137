import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../main.css';
import './bet.css';
// import NavLink from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import MatchesOdd from '../Matches/MatchesOdds';
import Bookmaker from '../Matches/Bookmaker';
import { Height } from '@mui/icons-material';
import Practice from '../../practice/Practice';
import Fency from '../../practice/Fancy';

const BetFrist = () => {
  const [activeTab, setActiveTab] = useState('market');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
    <div className="container-fluid p-0 m-0">
      <div className="row no-gutters">
        <div className="col-12 p-0">
          <div className="d-flex flex-row justify-content-between">
            <button
              onClick={() => handleTabClick('market')}
              className={`tab-button ${activeTab === 'market' ? 'active-tab' : ''}`}
            >
              Market
            </button>
            <button
              onClick={() => handleTabClick('openBet')}
              className={`tab-button ${activeTab === 'openBet' ? 'active-tab' : ''}`}
            >
              Open Bet
            </button>
          </div>
        </div>
      </div>

      <div className="content p-0 m-0">
        {activeTab === 'market' && (
          <div className="row tab1 m-0 p-0">
          <MatchesOdd />           
          <Bookmaker />
          {/* <Practice /> */}
          <Fency />
          </div>
         
        
        
        
          

        )}
         


{/* open bet ki coding */}

        {activeTab === 'openBet' && <p>OPEN BET</p>}
      </div>

      <div className='row' style={{Height:'200px'}}></div>
    </div>
<div className='col-12 Bookmark'>
  <p>end</p>
</div>
    </>
  );
};

export default BetFrist;
