
import '../home/home.css';

// import img2 from '../../../assets/main-banner2.webp';
import img3 from '../../../assets/bonus-1 (1).webp';
 import img4 from '../../../assets/bonus-10.jpg';
 import img5 from '../../../assets/bonus-11.jpg';
 import img6 from '../../../assets/RDGIF-1.jpg';
 import img7 from '../../../assets/RDGIF-2.jpg';
 import bgbaner from '../../../assets/pg-banner.webp'
 import Crousel from '../Crousel/Crousel';
 import Cricket from '../cricket/Cricket';
 import Soccer from '../soccer/Soccer';
 
 // data k liye coding
 import React, { useState } from 'react';
import { FaMapPin } from 'react-icons/fa';


 function Home() {

  // Placeholder data use karo taaki boxes show ho sake
  const [matchData, setMatchData] = useState({
    team1Score: '10/5',
    team2Score: '98/7',
    overs: '12.4',
    runs: '120',
    runRate: '7.5',
    requiredRunRate: '8.2'
  });


  return (
    <>
<div className='container-fluid home1'>
  <div className='row'>
    {/* <div className='col-12'>
      <img src={img2} className='img-fluid' style={{ height: 'auto', width: '100%' }} alt="Responsive Image"/>
    </div> */}
    <div className='benner'>
      
    </div>
  </div>
  <div className='row'>
    <div className='col-4 mt-2'>
      <img src={img3} className='img-fluid setup' style={{ height: 'auto', width: '100%' }} alt="Responsive Image"></img>
    </div>
    <div className='col-4 mt-2'>
      <img src={img4} className='img-fluid' style={{ height: 'auto', width: '100%' }} alt="Responsive Image"></img>
    </div>
    <div className='col-4 mt-2'>
      <img src={img5} className='img-fluid' style={{ height: 'auto', width: '100%' }} alt="Responsive Image"></img>
    </div>
  </div>
  <div className='row'>
    <div className='col-12 col-md-6 mt-2'>

    <img src={img6} className='img-fluid' style={{ height: 'auto', width: '100%' }} alt="Responsive Image"></img>
    </div>
    <div className='col-12 col-md-6 mt-2'>
    <img src={img7} className='img-fluid' style={{ height: 'auto', width: '100%' }} alt="Responsive Image"></img>
    </div>
  </div>
{/* crousel ki coding yaha karni hai */}

  <div className='raw '>
    <div className='col-12'>
      <h6 className='text-white fw-bold mt-4'>TOP GAMES</h6>
    </div>
    <div className='col-12'>
    < Crousel hideBackground={true} />
    </div>
  </div>

    </div>

    <div className='container-fluid'>
   
    <Cricket  />
  
    </div>
    <div className='container-fluid'>
     
  
  
    </div>

    <div className='container-fluid'>
     
     <Soccer hideBackground1={true} />
   
     </div>

     {/* <div className='container-fluid'>
      <div className='row'><img src={bgbaner}   /></div>
      
     </div> */}
 



   </>
  )
}
export default Home;



{/* matches data k liye coding */}
{/* <div className='container-fluid'>
        <div className='row text-white newf fw-bold'>Cricket</div>
        
        <div className='row'>
          <div className='col-12  '>
            <span className='fw-bold  '>India vs  New Zeeland      
                       </span><span style={{color:'red'}} className='ms-2 fw-bold'>Live Now</span>
          </div>
          <div className='col-3 d-flex justify-content-end  '>
           
          </div>
          <div className='col-10 '>
            <p className='F12 mb-2 mt-2'>
              <i className="fa-solid fa-stopwatch ">  </i>
              <span className='match1'> 15 OCT 8:30 | Test Matches </span>
            </p>
          </div>
          <div className='col-2 d-flex justify-content-end'>
  <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
    <li>
    
    </li>
  </ul>
</div>

        </div>

        <div className='row text-center fw-bold'>
          <div className='col-4  '>
            <div className='row '>
              <div className='col-5 ms-2 yogi  '>{matchData.team1Score}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.team2Score}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.overs}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.runs}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row'>
              <div className='col-5 yogi'>{matchData.runRate}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.requiredRunRate}</div>
            </div>
          </div>
        </div>







      
        <div className='row mt-2'>
          <div className='col-12  '>
            <span className='fw-bold match1 '>Pakistan vs England          
                   </span><span style={{color:'red'}} className='ms-2 fw-bold'>Live Now</span>
          </div>
          <div className='col-3 d-flex justify-content-end  '>
           
          </div>
          <div className='col-12 '>
            <p className='F12 mb-2 mt-2'>
              <i className="fa-solid fa-stopwatch ">  </i>
              <span className='text-dark'> 18 OCT 8:30 | Test Matches</span>
            </p>
          </div>
        
        </div>

        <div className='row text-center fw-bold'>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 ms-2 yogi'>{matchData.team1Score}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.team2Score}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.overs}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.runs}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.runRate}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.requiredRunRate}</div>
            </div>
          </div>
        </div>

        

        <div className='container'>
          <div className='row' style={{height:'100px'}}></div>
        </div>


      </div>
 */}