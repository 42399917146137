import React,{useState} from 'react'
import './demo.css';
import Cricket from '../Pages/cricket/Cricket';
import Soccer from '../Pages/soccer/Soccer'
import Slider from './Slider';




export default function Demo() {

  return (
    <>
   <Slider />
    <div className='container-fluid'>

        {/* <div className='row'>
        <div className="slider-container">
      <button onClick={goToPrevious} className="nav-button">❮</button>
      <div className="image-container">
        <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="slide-image" />
      </div>
      <button onClick={goToNext} className="nav-button">❯</button>
    </div>
        </div> */}

        

       
        <Cricket hideBackground={true} />
        <Soccer hideBackground={true} />
      
    </div>
    </>
  )
}
