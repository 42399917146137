import React from "react";
import{BrowserRouter as Router, Route,Routes,} from 'react-router-dom';
import './main.css';
import Home from './Components/Pages/home/Home';
import Footer from "./Components/Pages/Footer/Footer";
import Header from "./Components/Pages/Header/Header";
import Crousel from "./Components/Pages/Crousel/Crousel";
import Sidebar from "./Components/Pages/Sidebar/Sidebar";
import Sidebar3 from "./Components/Pages/Sidebar/sidebar3/Sidebar3";
import Inplay from "./Components/Pages/inplay/Inplay";
import Layout from "./Components/layout/Layout";
import Sidebar2 from "./Components/Pages/Sidebar/Sidebar2";
import Cricket from "./Components/Pages/cricket/Cricket";
import Login from "./Components/login/Login";
import Demo from "./Components/demo/Demo";
import Slider from "./Components/demo/Slider";
import BetFrist from "./Components/Pages/BetOdds/BetFrist";
import MatchesOdds from "./Components/Pages/Matches/MatchesOdds";
import Matchesdata from "./Components/Pages/Matches/Matchesdata";
import Soccer from "./Components/Pages/soccer/Soccer";
import Bookmaker from "./Components/Pages/Matches/Bookmaker";
import Practice from "./Components/practice/Practice";
import Fancy from "./Components/practice/Fancy";


const  App =()=>{

  

  return(
  <>




  <Router>
    <Header  />

   
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/Crousel" element={<Crousel/>} />
      <Route path="/Sidebar" element={<Sidebar  />} />
      <Route path="/Inplay" element={<Inplay/>} />
      <Route path="/Layout" element={<Layout/>} />
      <Route path="/Sidebar2" element={<Sidebar2/>} />
      <Route path="/Sidebar3" element={<Sidebar3/>} />
      <Route path="/Cricket" element={<Cricket/>} />
      <Route path="/Cricket" element={<Cricket/>} />
      <Route path="/Login" element={<Login/>} />
      <Route path="/Demo" element={<Demo/>} />
      <Route path="/Slider" element={<Slider/>} />
      <Route path="/Betfirst" element={<BetFrist/>} />
      <Route path="/MatchesOdds" element={<MatchesOdds/>} />
      <Route path="/Matchesdata" element={<Matchesdata/>} />
      <Route path="/Soccer" element={<Soccer/>} />
      <Route path="/Bookmaker" element={<Bookmaker/>} />
      <Route path="/Practice" element={<Practice/>} />
      <Route path="/Fancy" element={<Fancy/>} />


    </Routes>
   
    <Footer />
  </Router>
  </>
  )
}
export default App;