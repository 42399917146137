import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import './Sidebar.css';
import homelogo from '../../../assets/homelogo.jpg';
import shera1 from '../../../assets/shera.img.avif';
import kabaddi1 from '../../../assets/kabaddi-13-136929.webp';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openItem, setOpenItem] = useState(null);
  const [openSubItem, setOpenSubItem] = useState(null); // For deeper submenu


  // sidebar me ul li k liye 
  const [openItem1, setOpenItem1] = useState(null);

  const handleItemClick1 = (item) => {
    setOpenItem(openItem1 === item ? null : item);
  };




  // Toggle sidebar on mobile
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Handle item toggle inside the sidebar
  const handleItemClick = (item) => {
    setOpenItem(openItem === item ? null : item); // Toggle first-level submenu
  };

  // Handle subitem toggle inside the sidebar
  const handleSubItemClick = (subItem) => {
    setOpenSubItem(openSubItem === subItem ? null : subItem); // Toggle second-level submenu
  };

  return (
    <>
      <div className={`sidebar-container ${isOpen ? 'open' : ''}`}>
        {/* Sidebar Icon for mobile */}
        <div className="sidebar-icon" onClick={toggleSidebar}>
          &#9776;
        </div>

        {/* Sidebar content */}
        <div className={`sidebar ${isOpen ? 'show' : ''}`}>
          {/* Close button for mobile */}
          <div className="close-btn" onClick={toggleSidebar}>
            &times;
          </div>
          <h4 className='s4'>Popular</h4>



          <ul>
            {/* Home and Inplay without submenus */}
            <li>
              <NavLink to="/" className='fw-bold sher'>
                <img src={homelogo} width={'30px'} height={'30px'} alt="Home" />
                <span className='fw-bold ms-3'>Home</span>
              </NavLink>
            </li>

            <li>
              <NavLink to="/" className='fw-bold sher'>
                <img src={shera1} width={'30px'} height={'30px'} alt="Inplay" />
                <span className='fw-bold ms-3'>Inplay</span>
              </NavLink>
            </li>
            
            {/* Kabaddi with submenu */}
            <li className="matchesdata-item" onClick={() => handleItemClick1('cricket')}>
              <img src={kabaddi1} height={'30px'} width={'30px'} className='fw-bold sher' alt="Kabaddi" />
              <span>cricket</span>
              <span className="matchesdata-toggle-icon">{openItem1 === 'cricket' ? '▼' : '▶'}</span>
              {openItem1 === 'kabaddi' && (
                <ul className='submenu'>
                  <li onClick={() => handleItemClick1('kabaddi1')} className='text-dark'>
                    Kabaddi 1
                    <span className="submenu-icon">{openItem1 === 'kabaddi1' ? 'v' : '>'}</span>
                    {openItem1 === 'kabaddi1' && (
                      <ul className="matchesdata-submenu">
                      <li className="matchesdata-subitem">
                        <NavLink to="/">CSA Four-Day Series Division One 2024-25</NavLink>
                      </li>
                      <li className="matchesdata-subitem">
                        <NavLink to="/">One Day Internationals</NavLink>
                      </li>
                      <li className="matchesdata-subitem">
                        <NavLink to="/">International Twenty20 Matches</NavLink>
                      </li>
                      <li className="matchesdata-subitem">
                        <NavLink to="/">WBBL</NavLink>
                      </li>
                      <li className="matchesdata-subitem">
                        <NavLink to="/">Super50</NavLink>
                      </li>
                      <li className="matchesdata-subitem">
                        <NavLink to="/">ICC Cricket World Cup League 2</NavLink>
                      </li>
                      <li className="matchesdata-subitem">
                        <NavLink to="/">ICC Cricket World Cup Challenge League</NavLink>
                      </li>
                    </ul>
                    )}
                  </li>
                  <li onClick={() => handleSubItemClick('kabaddi2')} className='text-dark'>
                    Kabaddi 2
                    <span className="submenu-icon">{openItem1 === 'kabaddi2' ? 'v' : '>'}</span>
                    {openSubItem === 'kabaddi2' && (
                      <ul className='submenu'>
                        <li><NavLink to="/kabaddi2-1">Kabaddi 2-1</NavLink></li>
                        <li><NavLink to="/kabaddi2-2">Kabaddi 2-2</NavLink></li>
                      </ul>
                    )}
                  </li>
                </ul>
              )}
            </li>

            {/* Services with submenu */}
            <li onClick={() => handleItemClick('services')}>
              <span>Services</span>
              <span className="submenu-icon">{openItem === 'services' ? 'v' : '>'}</span>
              {openItem === 'services' && (
                <ul className='submenu'>
                  <li onClick={() => handleSubItemClick('services1')} className='text-dark'>
                    Service 1
                    <span className="submenu-icon">{openSubItem === 'services1' ? 'v' : '>'}</span>
                    {openSubItem === 'services1' && (
                      <ul className='submenu'>
                        <li><NavLink to="/services1-1">Service 1-1</NavLink></li>
                        <li><NavLink to="/services1-2">Service 1-2</NavLink></li>
                      </ul>
                    )}
                  </li>
                  <li onClick={() => handleSubItemClick('services2')} className='text-dark'>
                    Service 2
                    <span className="submenu-icon">{openSubItem === 'services2' ? 'v' : '>'}</span>
                    {openSubItem === 'services2' && (
                      <ul className='submenu'>
                        <li><NavLink to="/services2-1">Service 2-1</NavLink></li>
                        <li><NavLink to="/services2-2">Service 2-2</NavLink></li>
                      </ul>
                    )}
                  </li>
                </ul>
              )}
            </li>

            {/* Contact with submenu */}
            <li onClick={() => handleItemClick('contact')} className='text-dark'>
              <span>Contact</span>
              <span className="submenu-icon">{openItem === 'contact' ? 'v' : '>'}</span>
              {openItem === 'contact' && (
                <ul className='submenu'>
                  <li onClick={() => handleSubItemClick('contact1')}>
                    Contact 1
                    <span className="submenu-icon">{openSubItem === 'contact1' ? 'v' : '>'}</span>
                    {openSubItem === 'contact1' && (
                      <ul className='submenu'>
                        <li><NavLink to="/contact1-1">Contact 1-1</NavLink></li>
                        <li><NavLink to="/contact1-2">Contact 1-2</NavLink></li>
                      </ul>
                    )}
                  </li>
                  <li onClick={() => handleSubItemClick('contact2')}>
                    Contact 2
                    <span className="submenu-icon">{openSubItem === 'contact2' ? 'v' : '>'}</span>
                    {openSubItem === 'contact2' && (
                      <ul className='submenu'>
                        <li><NavLink to="/contact2-1">Contact 2-1</NavLink></li>
                        <li><NavLink to="/contact2-2">Contact 2-2</NavLink></li>
                      </ul>
                    )}
                  </li>
                </ul>
              )}
            </li>
          </ul>


        </div>
      </div>
    </>
  );
};

export default Sidebar;
