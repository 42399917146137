import React from 'react'
import { NavLink } from "react-router-dom";
import '../../../main.css';
import './inplay.css';
import Cricket from '../cricket/Cricket';
import Sidebar from '../Sidebar/Sidebar';
import shera1 from '../../../assets/shera.img.avif'
import game112 from '../../../assets/gaming.jpg';
import cricket1 from '../../../assets/cricket.jpg';
import soccer1 from '../../../assets/soccer.jpg';
import tennis1 from '../../../assets/tannis.jpg';
import horse1 from '../../../assets/horse.jpg';
import dog1 from '../../../assets/dog.jpg';
import kabaddi1 from '../../../assets/kabaddi-13-136929.webp';




 function Inplay() {
  return (
   <>
    <div className='container-fluid '>
    <div className='row fw-bold matched-bat border border-info '> IN-PLAY</div>
        <div className='row'>

            <div className='col-md-2 border popular-section matched-bat'>
              {/* <Sidebar /> */}

              <h2> sidebar view here</h2>
            </div>
           
            <div className='col-md-8 border'>
        <div className="menu-container1">
      <ul className="menu-list1">
     
        <li className=''><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={shera1} height={'50px'} width={'50px'} className='shera1 shera3 '></img><br></br>
        <span   className='fw-bold '>Watch Live</span>
        </NavLink></li>
        <li><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={game112} height={'50px'} width={'50px'} className='shera1 shera4 '></img><br></br>
        <span   className='text-bold'>All Game</span>
        </NavLink></li>
        <li><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={cricket1} height={'50px'} width={'50px'} className='shera1 shera2 '></img><br></br>
        <span   className='text-bold'>Cricket</span>
        </NavLink></li>
        <li><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={soccer1} height={'50px'} width={'50px'} className='shera1 '></img><br></br>
        <span   className='text-bold'>Soccer</span>
        </NavLink></li>
        <li><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={tennis1} height={'50px'} width={'50px'} className='shera1 '></img><br></br>
        <span   className='text-bold'>Tennis</span>
        </NavLink></li>
        <li><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={horse1} height={'50px'} width={'50px'} className='shera1 '></img><br></br>
        <span   className='text-bold'>Horse</span>
        </NavLink></li>
        <li><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={dog1} height={'50px'} width={'50px'} className='shera1 '></img><br></br>
        <span   className='text-bold'>GreyHound</span>
        </NavLink></li>
        <li><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={kabaddi1} height={'50px'} width={'50px'} className='shera1 '></img><br></br>
        <span   className='text-bold'>Kabaddi</span>
        </NavLink></li>
      </ul>
    </div>
    </div>



<div className='container-fluid'>

  <Cricket hideBackground={true}  />
  
  </div>


    {/* <div className='col-md-3 matched-bat-section border'>
        <div className='row matched-bat'>Matched Bat</div>
        <div className='row'></div>
    </div> */}




        </div>
    </div>
   </>
  )
}
export default Inplay;
