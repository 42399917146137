import React from 'react';
import './Sidebar2.css'; // Make sure to create this CSS file
import homelogo from '../../../assets/homelogo.jpg'

const Sidebar2 = () => {
  return (
    <div className="desktop-sidebar2">
      <div className="sidebar2">
        {/* Your sidebar code goes here */}
        <h4 className="sidebar2-heading">Popular</h4>
        <ul className="category2-list">
          <li className="category2-item">
            <div className="category2-header">
              <img src={homelogo} alt="Icon" className="category2-icon" />
              <span>Category 1</span>
              <span className="expand-icon">+</span>
            </div>
            <ul className="subcategory2-list">
              <li className="subcategory2-item">
                <span>Subcategory 1</span>
                <span className="expand-subitem">+</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar2;
