import React,{useState,useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import '../../../main.css'
import '../Header/header.css'; // CSS file for custom styles
import img1 from '../../../assets/radhe1logo.jpg';
import Sidebar from '../Sidebar/Sidebar';

 import shera1 from '../../../assets/shera.img.avif'
// import shera1 from '../../../assets/watchliveicon.jpg'
import cricket1 from '../../../assets/cricket.jpg';
import SOCCER from '../../../assets/soccer.jpg';
import tennis from '../../../assets/tannis.jpg';
import horse from '../../../assets/horse.jpg';
import Dog from '../../../assets/dog.jpg';




const Header = ( {}) => {


  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
      setIsOpen(!isOpen);
  };

// foe date and time 
  const [currentTime, setCurrentTime] = useState('');

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000); // Update every second

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);



  return (
    <>
    <div className='container-fluid  header-root'>
      <div className='row'>
      <div className=" col-8">
        
          <marquee className="marquee fw-bold" behavior="scroll" direction="left">
          Enjoy Unmatched Betting Excitement and Access 500+ Casino and Online Game
          </marquee>
    
        </div>
         <div className='col-md-3 col-4    shrr'>  <p >{currentTime}</p> 
        </div> 
      </div>
      
      <div className='row '>
        <div className='col-5 col-md-4 '>


        
          <div className='row  '>
            <div className='col-8 col-md-3 d-flex justify-content-end ms-2'>
        <p className='mt-2  mr-2'> <Sidebar /> </p>
           
            <img src={img1} alt="Logo" className="img-fluid inter ms-2" style={{ height: '60px' }} />
            </div>

           

            <div className='col-2 col-sm-3'>
  <h1 className='ram' style={{ display: 'inline-block', marginBottom: '0' }}>RADHE</h1>
  <h6 
    className='fontf header-roottext' 
    style={{
      fontFamily: 'Tahoma',
      display: 'inline-block',
      marginLeft: '50px' 
    }}
  >
    EXCHANGE
  </h6>
</div>


            
          </div>
          
        </div>
        <div className="col-7 col-md-7 d-flex justify-content-end">
          <button type='button' className='btn btn-primary mt-3 fw-bold cont-btn'>Login</button>
        </div>
      </div>
    </div>

    {/* menu list code here  */}

    <div className='container-fluid   '>
        <div className='row'>
        <div className="menu-container">
      <ul className="menu-list ">
        <li><NavLink to="/" className=' sher text-white'>Home </NavLink> </li>
        {/* <li className='border border-info'><NavLink to="/Inplay" className='fw-bold text-white sher '>In-Play</NavLink></li> */}
        
        <li className=' '><NavLink to="/Inplay" className='fw-bold sher  '>
        <img src={shera1} className='image1 ms-3'></img><br></br>
        <span   className='fw-bold text-light  '>IN-PLAY</span>
        </NavLink></li>
        <li className=''><NavLink to="/Cricket" className='fw-bold sher  '>
        <img src={cricket1}  className='image1 ms-4'></img><br></br>
        <span   className='fw-bold flashing-text'>CRICKET </span>
        </NavLink></li>
        <li className=''><NavLink to="/Soccer" className='fw-bold sher  '>
        <img src={SOCCER}  className='image1 ms-4'></img><br></br>
        <span   className='fw-bold text-light '>SOCCER </span>
        </NavLink></li>

        <li className=''><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={tennis} className='image1 ms-3'></img><br></br>
        <span   className='fw-bold text-light ' style={{marginTop:'-100px'}}>TENNIS </span>
        </NavLink></li>
        <li className=''><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={horse} className='image1 ms-3'></img><br></br>
        <span   className='fw-bold text-light '>HORSE </span>
        </NavLink></li>
        <li className=''><NavLink to="/In-play" className='fw-bold sher  '>
        <img src={Dog}   className='image1 ms-4'></img><br></br>
        <span   className='fw-bold text-light '>GREYHOUND </span>
        </NavLink></li>
       
        <li><NavLink to="/" className=' text-white sher '>Multi Market</NavLink></li>
        <li><NavLink to="/" className=' text-white sher '>Cricket</NavLink></li>
        <li><NavLink to="/" className=' text-white sher '>Soccer</NavLink></li>
        <li><NavLink to="/" className=' text-white sher '>Tennis</NavLink></li>
        <li><NavLink to="/" className='text-white sher '>Election</NavLink></li>
        <li><NavLink to="/" className=' text-white sher '>Horse Racing</NavLink> </li>
        <li><NavLink to="/" className=' text-white sher '>Greyhound Racing</NavLink> </li>
      </ul>
    </div>
        </div>
    </div>
   
    </>
  );
};

export default Header;
