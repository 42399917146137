import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Pages/Matches/bookmaker.css';
import { PlaceholderButton } from 'react-bootstrap';

const Practice = () => {
  const [isTableVisible1, setTableVisible1] = useState(true);
  const [isTableVisible2, setTableVisible2] = useState(true);
  const [timeoutId, setTimeoutId] = useState(null);

  const bookmaker1Data = [
    { team: 'India', back: 1.5, lay: 2.38 },
    { team: 'Australia', back: 180, lay: 2.34 },
  ];

  const bookmaker2Data = [
    { team: 'England', back: 1.8, lay: 2.5 },
    { team: 'New Zealand', back: 1.65, lay: 2.1 },
    { team: 'South Africa', back: 1.9, lay: 2.3 },
  ];

  const [selectedBet1, setSelectedBet1] = useState(null);
  const [selectedBet2, setSelectedBet2] = useState(null);
  const [points, setPoints] = useState(0);
  const [amount, setAmount] = useState(0);
  const [showBettingSection1, setShowBettingSection1] = useState(false);
  const [showBettingSection2, setShowBettingSection2] = useState(false);
 const [betBackgroundColor, setBetBackgroundColor] = useState('');
  const [newAmountInput, setNewAmountInput] = useState(true);
 

  const handleToggle = (bookmaker) => {
    if (bookmaker === 1) {
      setTableVisible1(!isTableVisible1);
    } else {
      setTableVisible2(!isTableVisible2);
    }
  };

  const handleBetClick = (bookmaker, team, pointsValue,color) => {

setBetBackgroundColor(color);

    if (bookmaker === 1) {
      setSelectedBet1({ team, pointsValue });
      setPoints(pointsValue);
      setAmount(100);
      setShowBettingSection1(true);
      setShowBettingSection2(false);
    } else {
      setSelectedBet2({ team, pointsValue });
      setPoints(pointsValue);
      setAmount(100);
      setShowBettingSection2(true);
      setShowBettingSection1(false);
    }
  };

  const handlePointsChange = (value) => {
    setPoints((prev) => Math.max(prev + value, 0));
  };

  const handleAmountChange = (value) => {
    setAmount((prev) => Math.max(prev + value, 0));
  };

  const handleQuickBet = (value) => {
    setAmount(value);
  };

  const handleCancel = () => {
    setShowBettingSection1(false);
    setShowBettingSection2(false);
    setSelectedBet1(null);
    setSelectedBet2(null);
    setPoints(0);
    setAmount(0);
  };
 


  const handlePlaceBet = (bookmaker) => {
    const selectedBet = bookmaker === 1 ? selectedBet1 : selectedBet2;
    alert(`Bet placed on ${selectedBet} with ${points} points and an amount of ${amount}`);
    handleCancel();

  };
  const handleNumberInput = (number) => {
    setAmount(newAmountInput ? number : amount * 10 + number);
    setNewAmountInput(false);
  };


  // const handlePlaceBet = () => {
  //   alert(`Bet placed on ${selectedBet.team} with ${points} points and an amount of ${amount}`);
  //   handleCancel();
  // };


  return (
    <div className="container-fluid">
      {/* Bookmaker 1 Section */}
      <div>
        <button onClick={() => handleToggle(1)} className="mat d-flex justify-content-between fw-bold w-100">
          <span>Bookmaker 1 - 0 commission</span>
          {isTableVisible1 ? <RemoveIcon /> : <AddIcon />}
        </button>

        {isTableVisible1 && (
          <div>
            <table className="table bg-light">
              <thead>
                <tr>
                  <td className="MIN1">MIN: 100 | MAX: 50000</td>
                  <th className="text-center col-2 border-dark back1" style={{ backgroundColor: '#74bdef', color: 'black' }}>
                    Back
                  </th>
                  <th className="text-center col-2 back2" style={{ backgroundColor: 'pink', color: 'black' }} >
                    Lay
                  </th>
                </tr>
              </thead>
              <tbody>
                {bookmaker1Data.map((item, index) => (
                  
                
                  <tr key={index} className="fw-bold">
                    <td>{item.team}</td>
                    <td
                      className="text-center col-3 fw-bold"
                      style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                      onClick={() => handleBetClick(1, item.team, item.back, '#74bdef')}
                    >
                      <span className="fw-bold">{item.back}</span>
                    </td>
                    <td
                      className="text-center col-3 fw-bold "
                      style={{ backgroundColor: 'pink', color: 'black', cursor: 'pointer' }}
                      onClick={() => handleBetClick(1, item.team, item.lay,'pink')}
                    >
                      <span className="fw-bold">{item.lay}</span>
                    </td>
                  </tr>

                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Betting Section for Bookmaker 1 */}
      {showBettingSection1 && (
        <div>
          <div className="row m-0 p-0">
            <div className="col-6 m-0 p-0 d-flex justify-content-between align-items-center">
              <button onClick={() => handlePointsChange(-0.1)} className="btn12">-</button>
              <span>{points}</span>
              <button onClick={() => handlePointsChange(0.1)} className="btn12">+</button>
            </div>
            <div className="col-6 m-0 p-0 d-flex justify-content-between align-items-center">
              <button onClick={() => handleAmountChange(-100)} className="btn12">-</button>
              <span>{amount}</span>
              <button onClick={() => handleAmountChange(100)} className="btn12">+</button>
            </div>
          </div>
          <div className="row m-0 p-0"  style={{ backgroundColor: betBackgroundColor }}>
            <div className="col-12 m-0 p-0 d-flex justify-content-between align-items-center" 
            >
              {[100, 500, 1000, 5000, 50000].map((value) => (
                <button key={value} onClick={() => handleQuickBet(value)} className="btn12" style={{ backgroundColor: betBackgroundColor }}>{value}</button>
              ))}
            </div>
          </div>



          <div className="row m-0 p-0">
                <div className="col-10 m-0 p-0">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, '0'].map((number) => (
                    <button key={number} onClick={() => handleNumberInput(number)} className="btn14">
                      {number}
                    </button>
                  ))}
                </div>
                <div className="col-2 border border-info m-0 p-0">
                  <button onClick={() => setAmount(0)} className="fw-bold m-0 p-0 text-dark btnm">
                    Clear
                  </button>
                </div>
              </div>


              <div className="row m-0 p-0">
                <div className="col-6 m-0 p-0">
                  <button onClick={handleCancel} className="btn btn-danger w-100 btnn mt-1 fw-bold">
                    Cancel
                  </button>
                </div>

                <div className="col-6 m-0 p-0">
                  <button onClick={handlePlaceBet} className="btn btn-success w-100 btnnnn mt-1 fw-bold">
                    Place Bet
                  </button>


                </div>

              </div>


        </div>
      )}

      {/* Bookmaker 2 Section */}
      <div>
        <button onClick={() => handleToggle(2)} className="mat d-flex justify-content-between fw-bold w-100">
          <span>Bookmaker 2 - 0 commission</span>
          {isTableVisible2 ? <RemoveIcon /> : <AddIcon />}
        </button>

        {isTableVisible2 && (
          <div>
            <table className="table bg-light">
              <thead>
                <tr>
                  <td className="MIN2">MIN: 100 | MAX: 50000</td>
                  <th className="text-center col-2 border-dark back1" style={{ backgroundColor: '#74bdef', color: 'black' }}>
                    Back
                  </th>
                  <th className="text-center col-2 back2" style={{ backgroundColor: 'pink', color: 'black' }}>
                    Lay
                  </th>
                </tr>
              </thead>
              <tbody>
                {bookmaker2Data.map((item, index) => (
                  <tr key={index} className="fw-bold">
                    <td>{item.team}</td>
                    <td
                      className="text-center col-3 fw-bold"
                      style={{ backgroundColor: '#74bdef', cursor: 'pointer' }}
                      onClick={() => handleBetClick(2, item.team, item.back,'#74bdef')}
                    >
                      <span className="fw-bold">{item.back}</span>
                    </td>
                    <td
                      className="text-center col-3 fw-bold"
                      style={{ backgroundColor: 'pink', color: 'black', cursor: 'pointer' }}
                      onClick={() => handleBetClick(2, item.team, item.lay, 'pink')}
                    >
                      <span className="fw-bold">{item.lay}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>

      {/* Betting Section for Bookmaker 2 */}
      {showBettingSection2 && (
        <div>
          <div className="row m-0 p-0" >
            <div className="col-6 m-0 p-0 d-flex justify-content-between align-items-center">
              <button onClick={() => handlePointsChange(-0.1)} className="btn12">-</button>
              <span>{points}</span>
              <button onClick={() => handlePointsChange(0.1)} className="btn12">+</button>
            </div>
            <div className="col-6 m-0 p-0 d-flex justify-content-between align-items-center">
              <button onClick={() => handleAmountChange(-100)} className="btn12">-</button>
              <span>{amount}</span>
              <button onClick={() => handleAmountChange(100)} className="btn12">+</button>
            </div>
          </div>
          <div className="row m-0 p-0" style={{ backgroundColor: betBackgroundColor }}>
            <div className="col-12 m-0 p-0 d-flex justify-content-between align-items-center" >
              {[100, 500, 1000, 5000, 50000].map((value) => (
                <button key={value} onClick={() => handleQuickBet(value)} className="btn12"
                style={{ backgroundColor: betBackgroundColor }}
                >{value}</button>
              ))}
            </div>
          </div>



          <div className="row m-0 p-0">
                <div className="col-10 m-0 p-0">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, '0'].map((number) => (
                    <button key={number} onClick={() => handleNumberInput(number)} className="btn14">
                      {number}
                    </button>
                  ))}
                </div>
                <div className="col-2 border border-info m-0 p-0">
                  <button onClick={() => setAmount(0)} className="fw-bold m-0 p-0 text-dark btnm">
                    Clear
                  </button>
                </div>
              </div>



              <div className="row m-0 p-0">
                <div className="col-6 m-0 p-0">
                  <button onClick={handleCancel} className="btn btn-danger w-100 btnn mt-1 fw-bold">
                    Cancel
                  </button>
                </div>
                <div className="col-6 m-0 p-0">
                  <button onClick={handlePlaceBet} className="btn btn-success w-100 btnnnn mt-1 fw-bold">
                    Place Bet
                  </button>
                </div>
              </div>
        </div>
      )}
    </div>
  );
};

export default Practice;
