import React, {useState} from 'react'
import './soccer.css';
import cricketimg from '../../../assets/Cricket-banner.jpg'

export default function Soccer({ hideBackground1  }) {

// Placeholder data use karo taaki boxes show ho sake
const [matchData, setMatchData] = useState({
    team1Score: '10/5',
    team2Score: '98/7',
    overs: '12.4',
    runs: '120',
    runRate: '7.5',
    requiredRunRate: '8.2'
  });


  return (
    <>
    <div className='container-fluid border border-danger    '>
        <div className='row '>
          <div  className={`soccer-component ${hideBackground1 ? 'no-background' : ''}`}>
        </div>
           </div>
            </div>
            
{/* matches ka data */}
<div className="soccer-container ">
      {/* Title Section */}
      <div className="soccer-title">Soccer</div>

      {/* Match Table */}
      <table className="match-table">
        <tbody>
          {/* First Match Row */}
          <tr className="match-card">
            <td className="match-row">
              <span className="status-dot"></span>
              <span className="teams">PSV VS GIRONA</span>
              <span className="live-status">Live Now</span>
              <span className="icon">📅</span>
              <span className="icon">F</span>
            </td>
            <td className="match-details">
              <span>26 Oct 09:30 | Ranji Trophy Elite 2024-25</span>
              <span className="icon">📌</span>
            </td>
            <td className="data-boxes">
              <div className="data-box ms-1 blue-box">1.98</div>
              <div className="data-box pink-box">1.87</div>
              <div className="data-box blue-box">1.98</div>
              <div className="data-box pink-box">1.87</div>
              <div className="data-box blue-box">2.14</div>
              <div className="data-box pink-box">2.18</div>
            </td>
          </tr>
          {/* Line separator */}
          <tr className="separator-row">
            <td colSpan="3">
              <hr className="separator-line" />
            </td>
          </tr>
          {/* Second Match Row */}
          <tr className="match-card">
            <td className="match-row">
              <span className="status-dot"></span>
              <span className="teams">Slovan Bratislava v Dinamo Zagreb</span>
              <span className="live-status">Live Now</span>
              <span className="icon">📅</span>
              <span className="icon">F</span>
            </td>
            <td className="match-details">
              <span>28 Oct 12:40 | WBBL</span>
              <span className="icon">📌</span>
            </td>
            <td className="data-boxes">
              <div className=" ms-1 data-box blue-box">1.86</div>
              <div className="data-box pink-box">1.87</div>
              <div className="data-box blue-box">2.14</div>
              <div className="data-box pink-box">2.18</div>
              <div className="data-box blue-box">2.14</div>
              <div className="data-box pink-box">2.18</div>
            </td>
          </tr>
            {/* Line separator */}
            <tr className="separator-row">
            <td colSpan="3">
              <hr className="separator-line" />
            </td>
          </tr>

            {/* thirld Match Row */}
            <tr className="match-card">
            <td className="match-row">
              <span className="status-dot"></span>
              <span className="teams">Bristol City v Sheff Utd</span>
              <span className="live-status">Live Now</span>
              <span className="icon">📅</span>
              <span className="icon">F</span>
            </td>
            <td className="match-details">
              <span>28 Oct 12:40 | WBBL</span>
              <span className="icon">📌</span>
            </td>
            <td className="data-boxes">
              <div className=" ms-1 data-box blue-box">1.86</div>
              <div className="data-box pink-box">1.87</div>
              <div className="data-box blue-box">2.14</div>
              <div className="data-box pink-box">2.18</div>
              <div className="data-box blue-box">2.14</div>
              <div className="data-box pink-box">2.18</div>
            </td>
          </tr>
         
        </tbody>
      </table>
    </div>










{/* 

<div className='container-fluid'>
        <div className='row text-white newf'>Cricket</div>
        
        <div className='row'>
          <div className='col-12  '>
            <span className='fw-bold match1 '>India vs  New Zeeland      
                       </span><span style={{color:'red'}} className='ms-2 fw-bold'>Live Now</span>
          </div>
          <div className='col-3 d-flex justify-content-end  '>
           
          </div>
          <div className='col-10 '>
            <p className='F12 mb-2 mt-2'>
              <i className="fa-solid fa-stopwatch ">  </i>
              <span className='text-dark'> 15 OCT 8:30 | Test Matches </span>
            </p>
          </div>
          <div className='col-2 d-flex justify-content-end'>
  <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
    <li>
    
    </li>
  </ul>
</div>

        </div>

        <div className='row text-center fw-bold'>
          <div className='col-4  '>
            <div className='row '>
              <div className='col-5 ms-2 yogi  '>{matchData.team1Score}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.team2Score}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.overs}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.runs}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row'>
              <div className='col-5 yogi'>{matchData.runRate}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.requiredRunRate}</div>
            </div>
          </div>
        </div>







      
        <div className='row mt-2'>
          <div className='col-12  '>
            <span className='fw-bold match1 '>Pakistan vs England          
                   </span><span style={{color:'red'}} className='ms-2 fw-bold'>Live Now</span>
          </div>
          <div className='col-3 d-flex justify-content-end  '>
          </div>
          <div className='col-12 '>
            <p className='F12 mb-2 mt-2'>
              <i className="fa-solid fa-stopwatch ">  </i>
              <span className='text-dark'> 18 OCT 8:30 | Test Matches</span>
            </p>
          </div>
        
        </div>

        <div className='row text-center fw-bold'>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 ms-2 yogi'>{matchData.team1Score}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.team2Score}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.overs}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.runs}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.runRate}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.requiredRunRate}</div>
            </div>
          </div>
        </div>

       

        
     
        <div className='row mt-2'>
          <div className='col-12  '>
            <span className='fw-bold match1'>Pakistan vs England          
                   </span><span style={{color:'red'}} className='ms-2 fw-bold'>Live Now</span>
          </div>
          <div className='col-3 d-flex justify-content-end  '>
            
          </div>
          <div className='col-12 '>
            <p className='F12 mb-2 mt-2'>
              <i className="fa-solid fa-stopwatch ">  </i>
              <span className='text-dark'> 18 OCT 8:30 | Test Matches</span>
            </p>
          </div>
        
        </div>

        <div className='row text-center fw-bold'>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 ms-2 yogi'>{matchData.team1Score}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.team2Score}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.overs}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.runs}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.runRate}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.requiredRunRate}</div>
            </div>
          </div>
        </div>
        
      
        <div className='row mt-2'>
          <div className='col-12  '>
            <span className='fw-bold match1 '>Pakistan vs England          
                   </span><span style={{color:'red'}} className='ms-2 fw-bold'>Live Now</span>
          </div>
          <div className='col-3 d-flex justify-content-end  '>
           
          </div>
          <div className='col-12 '>
            <p className='F12 mb-2 mt-2'>
              <i className="fa-solid fa-stopwatch ">  </i>
              <span className='text-dark'> 18 OCT 8:30 | Test Matches</span>
            </p>
          </div>
        
        </div>

        <div className='row text-center fw-bold'>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 ms-2 yogi'>{matchData.team1Score}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.team2Score}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.overs}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.runs}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.runRate}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.requiredRunRate}</div>
            </div>
          </div>
        </div>

       

        
       
        <div className='row mt-2'>
          <div className='col-12  '>
            <span className='fw-bold match1 '>Pakistan vs England          
                   </span><span style={{color:'red'}} className='ms-2 fw-bold'>Live Now</span>
          </div>
          <div className='col-3 d-flex justify-content-end  '>
        
          </div>
          <div className='col-12 '>
            <p className='F12 mb-2 mt-2'>
              <i className="fa-solid fa-stopwatch ">  </i>
              <span className='text-dark'> 18 OCT 8:30 | Test Matches</span>
            </p>
          </div>
        
        </div>

        <div className='row text-center fw-bold'>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 ms-2 yogi'>{matchData.team1Score}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.team2Score}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.overs}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.runs}</div>
            </div>
          </div>
          <div className='col-4 '>
            <div className='row '>
              <div className='col-5 yogi'>{matchData.runRate}</div>
              <div className='col-5 ms-2 yogi1'>{matchData.requiredRunRate}</div>
            </div>
          </div>
        </div>





        <div className='container'>
          <div className='row' style={{height:'100px'}}></div>
        </div>


      </div>
 */}



    </>
  )
}
